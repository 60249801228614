import axios from 'redaxios';
import { WeatherWarning } from 'types/dataApi';

const getWeatherWarningsBE = async (): Promise<WeatherWarning> => {
	const res = await axios.get(
		`https://data.buienradar.nl/1.0/weatherwarningsforcountry/be`
	);
	return res.data;
};

export default getWeatherWarningsBE;
