import SiteSearch from 'app/SiteSearch/SiteSearch';
import Template from 'app/Template/Template';
import { GetServerSideProps } from 'next';
import { dehydrate, QueryClient } from 'react-query';

import { ImageLiteRadars } from 'types/imageRadars';
import { Page } from 'types/siteAPI';
import { defaultLocations } from 'utils/contexts/LocationsContext';
import getObservationsByWeatherStationId from 'utils/getObservations';
import { getRadarImagesByType } from 'utils/getRadarImages';
import getWeatherReport from 'utils/getWeatherReport';
import useAppLocation from 'utils/hooks/useAppLocation';
import useIsWindowSize from 'utils/hooks/useIsWindowSize';
import { getRelevantWeatherThumbnails } from 'utils/getRelevant';
import TextLink from 'components/TextLink/TextLink';
import getWeatherWarningsBE from 'utils/getWeatherWarnings';
import { useQuery } from 'react-query';

import cssVariables from 'styles/variables.module.scss';

function Home({ page }: { page: Page }) {
	const { template, controls } = page;
	const isInWindow = useIsWindowSize({ mediaQuery: cssVariables.desktopS });
	const { data, error } = useQuery(
		['weatherWarningsData'],
		getWeatherWarningsBE,
		{
			retry: false,
		}
	);

	return (
		<>
			{isInWindow && <SiteSearch variant="label" labelText="Vinden: " />}
			{(data?.color === 'ORANGE' || data?.color === 'RED') &&
				<div style={{ backgroundColor: data?.color }}>
					<TextLink
						href="/belgie/weerbericht/weerwaarschuwingen"
						appearance="warningsbar"
					>
						{data?.teasertext}
					</TextLink>
				</div>
			}

			<Template
				templateName={template}
				maincontent={controls.maincontent}
				maincontentbottom={controls.maincontentbottom}
				subcontent={controls.subcontent}
				sidebar={controls.sidebar}
				bottom={controls.bottom}
			/>
		</>
	);
}

export const getServerSideProps: GetServerSideProps = async (context) => {
	const queryClient = new QueryClient();
	const appLocation = useAppLocation();
	const defaultLocation = defaultLocations[appLocation];
	const { weatherstationid } = defaultLocation;
	const defaultRadarMap =
		appLocation === 'BE'
			? ImageLiteRadars.RadarMapRain5mBE
			: ImageLiteRadars.RadarMapRain5mNL;

	/* Nextjs doesn't provide an option to fetch data serverside from within a component.
	 * To overcome this issue for now is to 'prefetch' the data on a page level so we can use
	 * the prefetched data within in the component by using the same query key.
	 */
	await Promise.all([
		queryClient.prefetchQuery('weatherReport', () =>
			getWeatherReport(appLocation)
		),
		queryClient.prefetchQuery(
			['weatherStationData', weatherstationid],
			() => getObservationsByWeatherStationId(weatherstationid)
		),
		queryClient.prefetchQuery(['radarImages', defaultRadarMap], () =>
			getRadarImagesByType({ type: defaultRadarMap })
		),
		queryClient.prefetchQuery(['relevantWeatherThumbnails'], () =>
			getRelevantWeatherThumbnails()
		),
	]);

	return {
		props: {
			dehydratedState: dehydrate(queryClient),
		},
	};
};

export default Home;
