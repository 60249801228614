import Spinner from 'components/Spinner';
import dynamic from 'next/dynamic';
import {
  Bottom,
  Maincontent,
  Maincontentbottom,
  Sidebar,
  Subcontent,
} from 'types/siteAPI';

const LoadingComponent = () => (
  <Spinner width={300} height={300} animation="loader" />
);
const HomeTemplate = dynamic(() => import('page-templates/home'), {
  loading: () => <LoadingComponent />,
});
const DefaultTemplate = dynamic(() => import('page-templates/default'), {
  loading: () => <LoadingComponent />,
});

interface ITemplate {
  templateName: string;
  maincontent: Maincontent[];
  maincontentbottom: Maincontentbottom[];
  subcontent: Subcontent[];
  sidebar: Sidebar[];
  bottom: Bottom[];
}

export default function Template({ templateName, ...props }: ITemplate) {
  switch (templateName) {
    case 'Home':
      return <HomeTemplate {...props} />;
    default:
      return <DefaultTemplate />;
  }
}
