import styles from './Spinner.module.scss';

interface ISpinner {
	animation: 'spinner' | 'loader';
	width: number;
	height: number;
}

const Spinner = ({
	animation = 'spinner',
	width = 80,
	height = 80,
}: ISpinner) => {
	return (
		<div
			className={styles.Container}
			data-width={width}
			data-height={height}
		>
			<div
				className={styles.AnimatedBackground}
				style={{ backgroundImage: `url(/${animation}.gif)` }}
			/>
		</div>
	);
};

export default Spinner;
